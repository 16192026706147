import page from '../pages/homepage/homepage.mobile';
import components from './map.components.mobile';

export const map = {
  components: components,
  pages: {
    homepage: {
      index: page,
    },
  },
};
