import HomepageIndex from './homepage';

export default class HomepageIndexMobile extends HomepageIndex {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
    this._initHeight();
  }

  _retrieveDOM() {
    this.dom.sectionHeader = this.dom.page.querySelector('.section-header');
  }

  _initHeight() {
    this.dom.sectionHeader.style.height = window.innerHeight + 'px';
  }

  bind() {
    window.addEventListener('scroll', this._scrollHandler.bind(this));
  }

  _scrollHandler(event) {
    if (this.getScroll() > window.innerHeight * 0.5) {
      this._addOpacity();
    } else {
      this._removeOpacity();
    }
  }

  getScroll() {
    return (
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0)
    );
  }

  _addOpacity() {
    this.Components.Navbar.dom.component.classList.add('opaque');
  }

  _removeOpacity() {
    this.Components.Navbar.dom.component.classList.remove('opaque');
  }
}
